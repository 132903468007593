import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

var firebaseConfig = {
  apiKey: "AIzaSyBTFk0MKivxp3NQdzLWPM9hSbIcHkKVcFI",
  authDomain: "portal-avalesdelcentro.firebaseapp.com",
  projectId: "portal-avalesdelcentro",
  storageBucket: "portal-avalesdelcentro.appspot.com",
  messagingSenderId: "313014932428",
  appId: "1:313014932428:web:77e32dbb20200177ffc369"
};

app.initializeApp(firebaseConfig);

const firebase = app.firestore()
const auth = app.auth()
const storage = app.storage()

export { firebase, auth, storage }